/* eslint-disable class-methods-use-this, prefer-destructuring, no-console */

class Animations {
	constructor() {
		this.animationContainer =
			document.getElementsByClassName("js-animation")[0];
		if (this.animationContainer !== undefined) {
			if (window.lottie === undefined) {
				this.loadJS(
					"/wp-content/themes/hs-starter-theme/js/vendor/lottie.js",
					() => {
						console.log("lottie loaded");
						this.init();
					}
				);
			} else {
				setTimeout(() => {
					this.init();
				}, 1000);
			}
		}
	}

	loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	}

	init() {
		const animationPath = this.animationContainer.getAttribute(
			"data-animation-path"
		);
		window.lottie.loadAnimation({
			container: this.animationContainer, // the dom element that will contain the animation
			renderer: "svg",
			loop: false,
			autoplay: true,
			path: animationPath, // the path to the animation json
			rendererSettings: {
				scaleMode: "noScale",
			},
		});
	}
}

export default Animations;
