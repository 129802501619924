/* eslint-disable class-methods-use-this */
import LazyLoading from "./lib/lazy-loading";
import Animations from "./lib/animations";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		window.lazyLoading = new LazyLoading();
		window.animation = new Animations();
	}
}
